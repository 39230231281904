import { axios } from 'lib/axios';
import { User } from 'types';
import { BASE_URL } from 'constants/baseUrl';

export const getUserProfile = async (): Promise<any> => {
  const url = `${BASE_URL}/get-user-profile`;

  const response = await axios.get<any>(url);

  // return user token
  return response.data.payload;
};