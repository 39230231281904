import styled from "styled-components";
import { User } from "types";
import { ParagraphText } from 'components/Elements/ParagraphText'
import { COLOR_PRIMARY } from '../../constants/colors';

const Container = styled.div`  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #1f1f1f;

  margin-right: 10px;
  margin-left: auto;`

const Thumbnail = styled.div`
width: 45px;
height: 45px;
overflow: hidden;
border-radius: 50%;

img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}`

const InfoContainer = styled.div`
margin-right: 10px;
margin-left: 10px;`


export const UserProfile = ({ user }: { user: User }) => {
  return (
    <Container>
      <Thumbnail>
        <img style={{ width: '100%' }} src={`https://robohash.org/${user._id}`} alt="User profile" />
      </Thumbnail>

      <InfoContainer>
        <ParagraphText margin={0} fontSize={15} color='#fff'>{`${user.firstName} ${user.lastName}`}</ParagraphText>
        <ParagraphText margin={0} fontSize={14} color={COLOR_PRIMARY}>{`TXTer`}</ParagraphText>
      </InfoContainer>
    </Container>
  );
};
