import { Dropdown } from "rsuite";
import styled from "styled-components";

export type DropdownItem = { label: string; onClick: () => void }

type DropdownMenuProps = {
    toggleElement: JSX.Element;
    items: DropdownItem[];
}

export const DropdownMenu = ({ toggleElement, items, ...rest }: DropdownMenuProps) => {
    return (
        <Dropdown
            placement="bottomEnd"
            renderToggle={(prop: any, ref: any) => (
                <div {...prop} ref={ref} >
                    {toggleElement}
                </div>
            )}
            {...rest}
        >
            {items.map(item => (<DropdownMenuItem key={item.label} label={item.label} onClick={item.onClick} />))}
        </Dropdown>
    )
}

const StyledDropdownItem = styled(Dropdown.Item)`
min-width: 150px;
text-align: center;
`
export const DropdownMenuItem = ({ label, onClick }: DropdownItem) => {
    return (
        <StyledDropdownItem onClick={onClick}>
            {label}
        </StyledDropdownItem>
    )
}