import { ParagraphText } from '../Elements/ParagraphText/ParagraphText';
import { NavBar } from '../Navigation/NavBar';
import { SideNav } from 'components/Navigation/SideNav';
import { useAppSelector } from 'store/hooks';
import { getSideMenuStatus } from 'store/slices/sideMenuSlice';
import styled from 'styled-components';

export type IMenuWrapperProps = {
  children: any;
};

const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #edf1f7;
  padding-top: 76px;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  @media (min-width: 992px) {
    padding-left: ${props => {
      return props.isOpen ? '256px' : '0px';
    }};
  }
`;

/**
 * Renders content within a navigation menu and side menu.
 * The navigation menu contains user profile info. Side menu
 * contains page links. This menu can opened and closed.
 * @param props
 * @returns
 */
export const MainLayout = (props: IMenuWrapperProps) => {
  // if true, side menu is open
  const sideMenuIsOpen = useAppSelector(getSideMenuStatus);

  return (
    <div>
      <NavBar />
      <SideNav />
      <Container isOpen={sideMenuIsOpen}>{props.children}</Container>
    </div>
  );
};