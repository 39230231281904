import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { User } from 'types';
import { dispatchLogout } from 'utils/storeDispatcher';
import { ParagraphText } from '../Elements/ParagraphText/ParagraphText';
import { DropdownItem, DropdownMenu } from '../Elements/Dropdown/Dropdown';
import styled from 'styled-components';
import { COLOR_BLACK, COLOR_LIGHT_BLUE, COLOR_SECONDARY } from 'constants/colors';
import { UserProfile } from '../Cards/UserProfile';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { getToken, getUser } from 'store/slices/userSessionSlice';
import { getSideMenuStatus, toggle } from 'store/slices/sideMenuSlice';

const StyledContainer = styled.div`
display: flex;
position: fixed !important;
background-color: ${COLOR_BLACK};
align-items: center;
width: 100%;
left: 0;
top: 0;
z-index: 99;
height: 4.75rem;
padding: 1.25rem;
margin: 0 auto;
box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
z-index: 999;
border-bottom: solid 1px #fff;`

const BrandContainer = styled.div`
@media (min-width: 992px) {
    order: 1;
  }
}`

const BrandLink = styled(NavLink)`
display: flex;
align-items: center;
ParagraphText-decoration: none;
 &:hover{
    ParagraphText-decoration: none;
 }
`

const Burger = styled.button<{
    sideMenuIsOpen: boolean;
    onClick: () => void
}>`
background-color: transparent;    
display: block;
width: 35px;
justify-content: center;
align-items: center;
cursor: pointer;
margin-left: auto;
margin-right: 0;
ParagraphText-align: center;
margin-top: 0px;
order: 3;
@media (min-width: 992px) {
  margin-right: 25px;
  margin-left: 0;
  order: 0;
}
svg {
  color: ${props => props.sideMenuIsOpen ? COLOR_SECONDARY : '#fff'};
  width: 25px !important;
  height: 35px;
  margin: auto;
}`

const StyledDropdown = styled(DropdownMenu)`
display: none;
order: 2;
margin-right: 10px;
margin-left: auto;
@media (min-width: 992px) {
    display: block;
  }
`

const navBarLinks: DropdownItem[] = [
    {
        label: 'Logout',
        onClick: dispatchLogout
    }
]
/**
 * Renders navigation menu.
 * @param param0 
 * @returns 
 */
export const NavBar = () => {
    const user = useAppSelector(getUser);
    const token = useAppSelector(getToken);
    const sideMenuIsOpen = useAppSelector(getSideMenuStatus)
    const renderProfileDropdown = (user: User) => (<StyledDropdown toggleElement={<UserProfile user={user} />} items={navBarLinks} />)
    const dispatch = useAppDispatch();
    
    return (
        <StyledContainer>
            <BrandContainer >
                <BrandLink end to="/" >
                    <ParagraphText fontSize={24} marginRight={2} color={COLOR_LIGHT_BLUE}>TXT-</ParagraphText>
                    <ParagraphText  margin={0} fontSize={24} color={'#fff'}>Portal</ParagraphText>
                </BrandLink>
            </BrandContainer>

            {token ? (
                <Burger sideMenuIsOpen={sideMenuIsOpen} onClick={() => {dispatch(toggle())}}>
                    <FontAwesomeIcon color='#fff' icon={faBars} />
                </Burger>
            ) : null}

            {user ? renderProfileDropdown(user) : null}
        </StyledContainer>
    );
};