import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes';

function App() {
  // render routes
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
