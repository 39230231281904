import { User } from "types";

const storagePrefix = 'txt_portal_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },

  getUser: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}user`) as string);
  },
  setUser: (token: User) => {
    window.localStorage.setItem(`${storagePrefix}user`, JSON.stringify(token));
  },
  clearUser: () => {
    window.localStorage.removeItem(`${storagePrefix}user`);
  },
  getLoginEmail: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}email`) as string);
  },
  setLoginEmail: (email: string) => {
    window.localStorage.setItem(`${storagePrefix}email`, JSON.stringify(email));
  },
  clearLoginEmail: () => {
    window.localStorage.removeItem(`${storagePrefix}email`);
  },
  getResetEmail: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}reset_password_email`) as string);
  },
  setResetEmail: (email: string) => {
    window.localStorage.setItem(`${storagePrefix}reset_password_email`, JSON.stringify(email));
  },
  clearResetEmail:() => {
    window.localStorage.removeItem(`${storagePrefix}reset_password_email`)
  }
};

export default storage;
