import { useEffect, useState } from "react";
import { serverErrorHandler } from "services/serverError.service";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserProfile } from "../api/getUser";
import { getEmail, getToken, getUser, logout } from "store/slices/userSessionSlice";
import { dispatchSetUser } from "utils/storeDispatcher";

export const useAccount = () => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useAppDispatch();
    const token = useAppSelector(getToken);
    //get Stored Email
    const storedEmail = useAppSelector(getEmail);
    
    //set stores login email in form state
    useEffect(() => {
        let mounted = true;

        const getUserDataAsync = async() => {
            try {   
                const response = await getUserProfile();
                dispatchSetUser(response);
                setIsLoading(false);
            } catch(e) {
                setIsLoading(false);
                serverErrorHandler(e, ()=> { dispatch(logout()) });
            }
        };

        if (mounted && token) getUserDataAsync();

        return () => {
            mounted = false;
        };
    }, [storedEmail, token]);

    return {
        isLoading
    }
};