export const COLOR_PRIMARY = '#0077b8'
export const COLOR_LIGHT_BLUE = '#00adef';
export const COLOR_BLACK = '#282828';
export const COLOR_SECONDARY = '#f05a22';
export const COLOR_RED = '#D72A16';
export const COLOR_GREEN = '#4caf50';

export const TAG_COLOR_BLUE = COLOR_PRIMARY;
export const TAG_COLOR_GREEN = COLOR_GREEN;
export const TAG_COLOR_RED = COLOR_RED;
export const TAG_COLOR_ORANGE = '#ff9800';