import { lazyImport } from 'utils/lazyImport';
import { Loader } from 'components/Elements/Loader/Loader';
import { Suspense } from 'react';

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes');

export const publicRoutes = [
  {
    path: '/*',
    element: (
      <Suspense fallback={<Loader theme="dark" isVisible={true} fullscreen={true} />}>
        <AuthRoutes />
      </Suspense>
    ),
  },
];
