import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import './styles/main.scss';
import { store } from './store/store';
import { injectStore } from 'utils/storeDispatcher';

// inject so we can extract instance of store and avoid circular imports
injectStore(store);

const description = ``;

ReactDOM.render(
  <>
    <Helmet>
      <title>TXT Portal </title>
      <meta name="description" content={description} />
    </Helmet>
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
