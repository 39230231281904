import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import storage from 'utils/storage';
import { User } from 'types';
import { axios } from 'lib/axios';

export interface UserSessionState {
  token: string | null;
  user: any | null;
  storedEmailed: string;
  storedResetEmail: string;
  history: string[];
}

const initialState: UserSessionState = {
  token: storage.getToken() || null,
  user: storage.getUser() || null,
  storedEmailed: storage.getLoginEmail() || '',
  storedResetEmail: storage.getResetEmail() || '',
  history: [],
};

export const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // stores login email in storage and redux store
    setStoredEmail: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.storedEmailed = action.payload;
        storage.setLoginEmail(action.payload);
      }
    },
    // clear login email from storage and redux store
    clearStoredEmail: state => {
      storage.clearLoginEmail();
      state.storedEmailed = '';
    },
    // stores jwt in storage and redux store
    signIn: (state, action: PayloadAction<{ token: string; user: User }>) => {
      if (action.payload) {
        storage.setToken(action.payload.token);
        state.token = action.payload.token;
        axios.defaults.headers.common.Authorization = `Bearer ${action.payload.token}`;
      }
    },
    /**
     * These will allow us to store email to use to reset password
     */
    setResetEmail: (state, action: PayloadAction<string>) => {
      if(action.payload) {
        state.storedResetEmail = action.payload;
        storage.setResetEmail(action.payload);
      }
    },
    clearStoredResetEmail: state => {
      storage.clearResetEmail();
      state.storedResetEmail = '';
    },
    setUser: (state, action: PayloadAction<User>) => {
      if (action.payload) {
        state.user = action.payload;
      }
    },
    // clear redux store of user data
    logout: state => {
      storage.clearToken();
      storage.clearUser();
      state.token = null;
      state.user = null;
    },
  },
});

export const { setStoredEmail, clearStoredEmail, setResetEmail, clearStoredResetEmail, logout, signIn, setUser } = userSessionSlice.actions;

// accessor for admin user
export const getUser = (state: RootState) => state.currentUser.user;

// accessor for admin user token
export const getToken = (state: RootState) => state.currentUser.token;

// get stored email
export const getEmail = (state: RootState) => state.currentUser.storedEmailed;

// get navigation history
export const getHistory = (state: RootState) => state.currentUser.history;

// get the user email set when user needs to reset a password
export const getStoredResetEmail = (state: RootState) => state.currentUser.storedResetEmail;

export default userSessionSlice.reducer;
