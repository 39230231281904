import {
  faFileAlt,
  faPeopleGroup,
  faHome
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { getUser } from 'store/slices/userSessionSlice';
import { useAppSelector } from '../store/hooks';

/**
 * Interface side menu dropdown links
 */
export type DropdownLink = {
  title: string;
  icon: JSX.Element;
  active: boolean;
  children: {
    title: string;
    icon: JSX.Element;
    to: string;
    type: 'internal' | 'external';
  }[];
};

/**
 * Side Menu links
 */
const LINKS: DropdownLink[] = [
  {
    title: 'SCLA',
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    active: false,
    children: [
      {
        title: 'Your Applications',
        icon: <FontAwesomeIcon icon={faFileAlt} />,
        to: '/app/scla/scla-applications',
        type: 'internal'
      }
    ]
  }
];

export default function useNavigationLinks() {
  const [links, setLinks] = useState<DropdownLink[]>([]);

  const user = useAppSelector(getUser);

  useEffect(() => {
    let mounted = true;

    function filterLinks() {
      if (user.type === 'super-admin') {
        setLinks(LINKS);
      } else {
        setLinks(LINKS);
      }
    }

    if (mounted && user) filterLinks();

    return () => {
      mounted = false;
    };
  }, [user]);

  const toggleLinkDropdown = (link: DropdownLink) => {
    link.active = !link.active;
    setLinks([...links]);
  };

  return { links, toggleLinkDropdown };
}
