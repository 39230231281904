import { Loader } from 'components/Elements/Loader/Loader';
import { MainLayout } from 'components/Layout/MainLayout';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUser } from 'store/slices/userSessionSlice';
import { lazyImport } from 'utils/lazyImport';
import { useAccount } from 'features/auth/hooks/useAccount';
import { useAppSelector } from '../store/hooks';

const { SclaRoutes } = lazyImport(() => import('features/scla'), 'SclaRoutes');

/**
 * Renders the routes. A loader is shown if user data is being fetched.
 * A loader is shown for routes that are lazy loaded
 * @returns 
 */
const App = () => {
  // fetch user profile data - data fetched on app load/reload
  const { isLoading } = useAccount();

  // get user from store
  const user = useAppSelector(getUser);

  return (
    <MainLayout>
      <Suspense
        fallback={
          // show light loader when user data is already fetched
          // otherwise show the dark loader
          user === null ? (
            <Loader theme="dark" isVisible={true} fullscreen={false} />
          ) : (
            <Loader theme="light" isVisible={true} fullscreen={false} />
          )
        }
      >
        {isLoading && user === null ? <Loader theme="dark" isVisible={true} fullscreen={true} /> : <Outlet />}
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'scla/*', element: <SclaRoutes /> },
    //   { path: 'txt-labs/*', element: <TxtLabsRoutes /> },
    //   { path: 'emails/*', element: <EmailRoutes /> },
    //   { path: 'hackathon/*', element: <HackathonRoutes /> },
    //   { path: 'scla/*', element: <SclaRoutes/>},
      { path: '', element: <Navigate to="/app/scla/scla-applications" /> },
      { path: '*', element: <Navigate to="/app/scla/scla-applications" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/app/scla/scla-applications" />,
  },
];

