import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ISideMenuState {
  show: boolean;
}

const initialState: ISideMenuState = {
  show: window.innerWidth < 768 ? false : true, // if window is bigger than 768, show menu
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    show: state => {
      // show the side menu
      state.show = true;
    },
    hide: state => {
      // hide the side menu
      state.show = false;
    },
    toggle: state => {
      // toggle menu
      state.show = !state.show;
    },
  },
});

export const { show, hide, toggle } = sideMenuSlice.actions;

// accessor for sidemenu status
export const getSideMenuStatus = (state: RootState) => state.sideMenu.show;

export default sideMenuSlice.reducer;
