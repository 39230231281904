import { ThunkMiddleware } from 'redux-thunk';
import { ISideMenuState, toggle } from 'store/slices/sideMenuSlice';
import { logout, setUser, UserSessionState } from '../store/slices/userSessionSlice';
import { AnyAction, EnhancedStore } from '@reduxjs/toolkit';
import { User } from 'types';

let store: EnhancedStore<
  {
    currentUser: UserSessionState;
    sideMenu: ISideMenuState;
  },
  AnyAction,
  [
    ThunkMiddleware<
      {
        currentUser: UserSessionState;
        sideMenu: ISideMenuState;
      },
      AnyAction,
      undefined
    >,
  ]
>;

export const injectStore = (
  _store: EnhancedStore<
    {
      currentUser: UserSessionState;
      sideMenu: ISideMenuState;
    },
    AnyAction,
    [
      ThunkMiddleware<
        {
          currentUser: UserSessionState;
          sideMenu: ISideMenuState;
        },
        AnyAction,
        undefined
      >,
    ]
  >,
) => {
  store = _store;
};

export const dispatchLogout = () => {
  store.dispatch(logout());
};

export const dispatchSideMenuToggle = () => {
  store.dispatch(toggle());
};

export const dispatchSetUser = (user: User) => {
  store.dispatch(setUser(user));
};