import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// styles
import 'bootstrap/dist/css/bootstrap.min.css'; // boostrap
import '../styles/main.scss';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (

    <Router>{children}</Router>

  );
};
