import { useRoutes } from 'react-router-dom';
import { publicRoutes } from './public';
import { useAppSelector } from 'store/hooks';
import { getToken } from 'store/slices/userSessionSlice';
import { protectedRoutes } from './protected';

export const AppRoutes = () => {
  // const auth = useAuth();
  // check user session
  const token = useAppSelector(getToken);

  // render the protedted routes only if we have a user token
  const routes = token ?  protectedRoutes : publicRoutes;

  // create routes
  const element = useRoutes([...routes]);

  // render the element home
  return <>{element}</>;
};
